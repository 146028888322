import React from "react";
// import TestimonialCard from "./TestimonialCard";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
// import { Autoplay, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "../style/testimonial.css";
import "../style/testimonialCard.css";
import palgharlogo from "../img/palgharlogo.jpg";
import mlogo from "../img/mlogo.png"

// export default function Testimonial() {
 
  const partnerships = [
    {
      logo: palgharlogo, // Replace with actual image paths
      name: "St. John College of Engineering and Management, Palghar",
    },
    {
      logo: mlogo, // Replace with actual image paths
      name: "Meerut Udyami Foundation",
    },

  ]
  export default function Testimonial() {
  return (
    <div className="technical-partnership">
      <div className="test-top">
         <h2>Technical Partnership </h2>
        <p>
       "our collaboration with Educational institute and industry leaders to develop innovative solutions and provide cutting-edge technology to our clients."
        </p>
       </div>
      <div className="partnership-container">
        {partnerships.map((partner, index) => (
          <div className="partner-card" key={index}>
            <img
              src={partner.logo}
              alt=""
              className="partner-logo"
            />
            <p className="partner-name">{partner.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};



//   const c1 = {
//     photo: palgharlogo,
//     // name: "St. John College of Engineering and Management, Palghar",
//     designation: "St. John College of Engineering and Management, Palghar",
//     // para: "We have partnered with Palghar college to offer students hands-on experience with drone technology, providing them with real-world exposure and technical training.",
//   };
//   const c2 = {
//     photo: mlogo,
//     designation: "Meerut Udyami Foundation ",
//   };
  
//   return (
//     <div className="test-container">
//       <div className="test-top">
//         <h2>Technical Partnerships  </h2>
//         <p>
//         "our collaboration with Educational institute and industry leaders to develop innovative solutions and provide cutting-edge technology to our clients."
//         </p>
//       </div>
//       <div className="carousel-tesimonial">
       
//             <div className="image">
//               <TestimonialCard details={c1} />
//             </div>
//             <div className="image">
//               <TestimonialCard details={c2} />
//             </div>
        
//       </div>
//     </div>
//   );
// }


