import React, { useRef } from "react";
import "../style/contact.css";
import add from "../img/contact/add.png";
import call from "../img/contact/call.png";
import mail from "../img/contact/mail.png";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadcrumpTemp from "./Template/BreadcrumpTemp";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ctzuckj", // Your service ID
        "template_re9e0rf", // Your template ID
        form.current,
        "_BPwmrrJ3nq4vf8AK" // Your public key
      )
      .then(
        (result) => {
          console.log(result.text);
          toast("Message Sent Successfully");
          form.current.reset(); // Clear the form after successful submission

        },
        (error) => {
          console.log(error.text);
          toast("Failed to Send Message");
        }
      );
  };

  const breadProp = {
    title: "Our Contact",
    text: "Join us in building a resilient future with the help of cutting-edge drone technology",
    arr: ["Home", "Contact"],
  };

  return (
    <div className="contact-outer">
      <BreadcrumpTemp bprops={breadProp} />
      <div className="contact">
        <div className="contact1">
          <h2 className="contact-who">
            <img
              src="https://devsdesign.net/products/html/konstruk/assets/images/shape-1.png"
              alt=""
            />{" "}
            LET'S TALK
          </h2>

          <form ref={form} className="form" onSubmit={sendEmail}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              required
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <br />
            <input
              type="tel"
              id="number"
              name="number"
              placeholder="Phone Number"
              required
            />
            <input
              type="text"
              id="website"
              name="website"
              placeholder="Your Website"
            />
            <br />
            <textarea
              id="message"
              name="message"
              placeholder="Your Message Here"
              required
            />
            <br />
            <button type="submit">Submit Now</button>
          </form>
        </div>
        <ToastContainer />

        <div className="Contact-Right-Side">
          <div className="card">
            <img src={add} alt="Address Icon" />
            <div className="card1">
              <h5>Address</h5>
              <p>Daman Industrial Estate</p>
              <p>Daman - 396210</p>
            </div>
          </div>

          <div className="card">
            <img src={mail} alt="Mail Icon" />
            <div className="card1">
              <h5>Email Us</h5>
              <p>
                <a href="mailto:vamaskylight@gmail.com">vamaskylight@gmail.com</a>
              </p>
            </div>
          </div>

          <div className="card">
            <img src={call} alt="Call Icon" />
            <div className="card1">
              <h5>Call Us</h5>
              <p>
                <a href="tel:+91-8320844583">+91-8320844583</a>
              </p>
            </div>
          </div>

          <a
            href="https://wa.me/918320844583"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-icon"
          >
            <i className="fab fa-whatsapp whats-app "></i>
          </a>

          <style jsx>{`
            .whatsapp-icon {
              position: fixed;
              width: 60px;
              height: 60px;
              bottom: 40px;
              right: 40px;
              background-color: #25d366;
              color: #fff;
              border-radius: 50px;
              text-align: center;
              font-size: 30px;
              box-shadow: 2px 2px 3px #999;
              z-index: 999;
            }
            .whats-app {
              margin-top: 16px;
            }

            @media screen and (max-width: 767px) {
              .whats-app {
                margin-top: 10px;
              }

              .whatsapp-icon {
                width: 40px;
                height: 40px;
                bottom: 20px;
                right: 10px;
                font-size: 22px;
              }
            }
          `}</style>
        </div>
      </div>
    </div>
  );
}
