// import React, { useEffect } from "react";

// export default function Post() {
//   useEffect(() => {
//     // Dynamically load the LinkedIn script
//     const script = document.createElement("script");
//     script.src =
//       "https://widgets.sociablekit.com/linkedin-page-posts/widget.js";
//     script.async = true;
//     script.defer = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div>
//       <div className="sk-ww-linkedin-page-post" data-embed-id="25457220"></div>
//     </div>
//   );
// }


// 


import React, { useEffect } from "react";
import "../style/post.css"; // Assuming you already have the CSS for styling

export default function Post() {
  useEffect(() => {
    // Dynamically load the LinkedIn script for SociableKit
    const script = document.createElement("script");
    script.src =
      "https://widgets.sociablekit.com/linkedin-page-posts/widget.js";
    script.async = true;
    script.defer = true;

    script.onload = () => {
      console.log("SociableKit script loaded successfully.");
    };

    script.onerror = () => {
      console.error("Failed to load the SociableKit script.");
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="post-container">
      <div
        className="sk-ww-linkedin-page-post"
        data-embed-id="25472940" // Replace with your actual embed ID
      ></div>
    </div>
  );
}
