
// import React, { useRef } from "react";
// import "../style/page2/allSectionP2.css";
// import BreadcrumpTemp from "./Template/BreadcrumpTemp";
// // import Section4 from "./Section4.jsx";
// import "../style/carrier.css";
// import carrier from "../img/carrier/drone_pilot.jpg";
// import emailjs from "emailjs-com";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";


// export default function Carrier() {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_2d9z3lr",  // Your service ID
//         "template_naq4c0n",  // Your template ID
//         form.current,
//         "U90_HEeU3pMbktTp3"  // Your public key
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//           toast("Message Sent Successfully");
//         },
//         (error) => {
//           console.log(error.text);
//           toast("Failed to Send Message");
//         }
//       );
//   };

//   const breadProp = {
//     title: "Career",
//     text: "",
//     arr: ["Home", "Career"],
//   };

//   const props = {
//     img: "s4.jpg",
//     title: "Career",
//     text1:
//       "Join our nationally recognized, award-winning team! Incredible opportunities available now. Competitive salary and benefits available. Most rewarding job in the country! Apply now!",
//   };

//   return (
//     <div>
//       <BreadcrumpTemp bprops={breadProp} />
//       <div className="all-section-p">
//         <div className="asp-left">
//           <div className="prod-img">
//             <img src={carrier} alt="Carrier" className="carrier-image" />
//             <form ref={form} className="carrier-form" onSubmit={sendEmail}>
//               <input
//                 type="text"
//                 name="name"
//                 id="name"
//                 placeholder="Your Name"
//                 required
//               />
//               <input
//                 type="email"
//                 name="email"
//                 id="email"
//                 placeholder="Your Email"
//                 required
//               />
//               <input
//                 type="tel"
//                 name="number"
//                 id="number"
//                 placeholder="Your Phone Number"
//                 required
//               />
//               <input
//                 type="text"
//                 name="position"
//                 id="position"
//                 placeholder="Your Position"
//                 required
//               />
//               {/* <div className="carrier-file">
//                 <label htmlFor="myfile" className="custom-file-upload">
//                   <i className="fa fa-cloud-upload"></i> Upload Resume
//                 </label>
//                 <input type="file" id="myfile" name="myfile" />
//               </div> */}
//               <button type="submit">Submit Now</button>
//             </form>
//           </div>
//           <ToastContainer />

//           <div className="sec2-prod-left">
//             <h1>{props.title}</h1>
//             <p>{props.text1}</p>
//           </div>
//         </div>
//         <div className="asp-right">
//           <div className="asp-right-2">
//             <div className="sec2-prod-right-inner">
//               <span className="tele-icon">
//                 <i className="fa-solid fa-phone"></i>
//               </span>
//               <div>
//                 <p>Have you any Questions?</p>
//                 <p>
//                   Call us <span className="ylo">Today!</span>
//                 </p>
//               </div>
//               <div className="dotted-line"></div>
//               <p className="call-num">
//                 <a href="tel:+91-8320844583">+91-8320844583</a>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <Section4 /> */}
//       <a
//         href="https://wa.me/918320844583"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="whatsapp-icon"
//       >
//         <i className="fab fa-whatsapp whats-app"></i>
//       </a>
//       <style jsx>{`
//         .whatsapp-icon {
//           position: fixed;
//           width: 60px;
//           height: 60px;
//           bottom: 40px;
//           right: 40px;
//           background-color: #25d366;
//           color: #fff;
//           border-radius: 50px;
//           text-align: center;
//           font-size: 30px;
//           box-shadow: 2px 2px 3px #999;
//           z-index: 999;
//         }
//         .whats-app {
//           margin-top: 16px;
//         }
//         @media screen and (max-width: 767px) {
//           .whats-app {
//             margin-top: 10px;
//           }
//           .whatsapp-icon {
//             width: 40px;
//             height: 40px;
//             bottom: 20px;
//             right: 10px;
//             font-size: 22px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// }

import React, { useRef } from "react";
import "../style/page2/allSectionP2.css";
import BreadcrumpTemp from "./Template/BreadcrumpTemp";
// import Section4 from "./Section4.jsx";
import "../style/carrier.css";
import carrier from "../img/carrier/drone_pilot.jpg";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Carrier() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ctzuckj", // Your service ID
        "template_m4dyocr", // Your template ID
        form.current,
        "_BPwmrrJ3nq4vf8AK" // Your public key
      )
      .then(
        (result) => {
          console.log(result.text);
          toast("Message Sent Successfully");
          form.current.reset(); // Clear the form after successful submission
        },
        (error) => {
          console.log(error.text);
          toast("Failed to Send Message");
        }
      );
  };

  const breadProp = {
    title: "Career",
    text: "",
    arr: ["Home", "Career"],
  };

  const props = {
    img: "s4.jpg",
    title: "Career",
    text1:
      "Join our nationally recognized, award-winning team! Incredible opportunities available now. Competitive salary and benefits available. Most rewarding job in the country! Apply now!",
  };

  return (
    <div>
      <BreadcrumpTemp bprops={breadProp} />
      <div className="all-section-p">
        <div className="asp-left">
          <div className="prod-img">
            <img src={carrier} alt="Carrier" className="carrier-image" />
            <form ref={form} className="carrier-form" onSubmit={sendEmail}>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
                required
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your Email"
                required
              />
              <input
                type="tel"
                name="number"
                id="number"
                placeholder="Your Phone Number"
                required
              />
              <input
                type="text"
                name="position"
                id="position"
                placeholder="Your Position"
                required
              />
              {/* <div className="carrier-file">
                <label htmlFor="myfile" className="custom-file-upload">
                  <i className="fa fa-cloud-upload"></i> Upload Resume
                </label>
                <input type="file" id="myfile" name="myfile" />
              </div> */}
              <button type="submit">Submit Now</button>
            </form>
          </div>
          <ToastContainer />

          <div className="sec2-prod-left">
            <h1>{props.title}</h1>
            <p>{props.text1}</p>
          </div>
        </div>
        <div className="asp-right">
          <div className="asp-right-2">
            <div className="sec2-prod-right-inner">
              <span className="tele-icon">
                <i className="fa-solid fa-phone"></i>
              </span>
              <div>
                <p>Have you any Questions?</p>
                <p>
                  Call us <span className="ylo">Today!</span>
                </p>
              </div>
              <div className="dotted-line"></div>
              <p className="call-num">
                <a href="tel:+91-8320844583">+91-8320844583</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Section4 /> */}
      <a
        href="https://wa.me/918320844583"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-icon"
      >
        <i className="fab fa-whatsapp whats-app"></i>
      </a>
      <style jsx>{`
        .whatsapp-icon {
          position: fixed;
          width: 60px;
          height: 60px;
          bottom: 40px;
          right: 40px;
          background-color: #25d366;
          color: #fff;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          box-shadow: 2px 2px 3px #999;
          z-index: 999;
        }
        .whats-app {
          margin-top: 16px;
        }
        @media screen and (max-width: 767px) {
          .whats-app {
            margin-top: 10px;
          }
          .whatsapp-icon {
            width: 40px;
            height: 40px;
            bottom: 20px;
            right: 10px;
            font-size: 22px;
          }
        }
      `}</style>
    </div>
  );
}
